<template>
  <div class="BetaWelcomePage">
    <div class="BWPContainer">
      <Navbar :hideProfileButton="true" />

      <!-- <div class="StepTip">
        <div class="StepTipImage"></div>

        <template v-if="step == 1">
          <span class="StepTipText"
            ><span class="BoldTipText">Давай познакомимся.</span> Заполни данные
            о себе, чтобы мы смогли узнать о твоих целях на нашем проекте.</span
          >
        </template>

        <template v-if="step == 2">
          <span class="StepTipText"
            ><span class="BoldTipText">Выбираем задачи. </span><br />1 шаг:
            Выбери и нажми на проект. Откроется список задач. <br />2 шаг:
            Выбери и нажми на задачу, чтобы узнать о ней подробнее. Ты можешь
            добавить несколько задач в Избранное, нажав на сердечко. <br />3
            шаг. Закрепи за собой приоритетную задачу, которую будешь выполнять.
            При необходимости, ты можешь её поменять.
          </span>
        </template>
      </div> -->

      <!-- 1 Этап регистрации -->
      <div class="FillProfileData">
        <span class="Head">Заполните данные</span>
        <div class="Inputs">
          <div class="InputWrap">
            <span class="Label">Регион *</span>

            <el-select
              v-model="region_selected"
              class="RDSelectField"
              placeholder="Выберите"
              size="large"
            >
              <el-option
                v-for="item in region_list"
                :key="item.name"
                :label="item.name"
                :value="item.name"
                @click="setUniveristiesList(item.name)"
              />
            </el-select>

            <input
              v-if="region_selected == 'Другое'"
              v-model="region_other"
              type="text"
              placeholder="Укажите название"
            />
          </div>

          <div class="InputWrap">
            <span class="Label">Учебное заведение *</span>

            <el-select
              v-model="univesities_id"
              class="RDSelectField"
              placeholder="Выберите"
              size="large"
            >
              <el-option
                v-for="item in univesities_list"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              />
            </el-select>

            <input
              v-if="
                univesities_list.find(
                  (obj) => obj.id == univesities_id && obj.name == 'Другое'
                )
              "
              v-model="univesities_other"
              type="text"
              placeholder="Укажите название"
            />
          </div>

          <div class="InputWrap">
            <span class="Label">Курс</span>

            <input
              ref="course"
              type="text"
              @input="(event) => checkCourseText(event.target.value)"
              placeholder="Ваш курс в ВУЗе (1-5)"
            />
          </div>

          <!-- Откуда о нас узнали? -->
          <div class="InputWrap">
            <span class="Label">Откуда о нас узнали?</span>

            <el-select
              v-model="reg_source"
              class="RDSelectField"
              placeholder="Выберите"
              size="large"
            >
              <el-option
                v-for="item in reg_source_list"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>

            <input
              v-if="reg_source == 'Другое'"
              v-model="reg_source_other"
              type="text"
              placeholder="Укажите откуда"
            />
          </div>

          <!-- Цель регистрации -->
          <div class="InputWrap">
            <span class="Label">Цель регистрации</span>

            <el-select
              v-model="reg_goal"
              multiple
              class="RDSelectField"
              placeholder="Выберите"
              size="large"
            >
              <template v-for="item in reg_goal_list">
                <el-option
                  v-if="
                    !(
                      item == 'Другое' &&
                      reg_goal != '' &&
                      reg_goal != 'Другое'
                    ) &&
                      !(
                        item != 'Другое' &&
                        reg_goal != '' &&
                        reg_goal == 'Другое'
                      )
                  "
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </template>
            </el-select>

            <input
              v-if="reg_goal == 'Другое'"
              v-model="reg_goal_other"
              type="text"
              placeholder="Укажите вашу цель"
            />
          </div>
        </div>
        <span class="Tip" v-if="Tip !== ''">{{ Tip }}</span>
        <GradientButton
          :style="nextIsAvailable ? '' : 'opacity: 0.5; cursor: default;'"
          class="FillButton noselect"
          ButtonText="Следующий шаг"
          @click.native="
            () => {
              if (nextIsAvailable) {
                nextBtn(1);
              }
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import RubleIcon from "@/components/CustomElements/RubleIcon.vue";
import JobHireIcon from "@/components/CustomElements/Icons/JobHireIcon.vue";

import Navbar from "@/components/Navbar/NavbarCombined.vue";
import GradientButton from "@/components/CustomElements/ButtonGradient.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";

import CircleQuestionIcon from "@/components/CustomElements/CircleQuestionIcon.vue";

import { getAllProject } from "@/api/project.js";
import { mapGetters, mapMutations } from "vuex";

import { userHasAttempts } from "@/api/career_guidance.js";

import { refresh } from "@/api/auth.js";

import {
  update,
  findById,
  getRegionAndUniver,
  getRegionAndUniverList,
  getUserRegionAndUniver,
} from "@/api/user.js";

import {
  addUserGoal,
  addUserSource,
  getUserRegistrationFields,
} from "@/api/registration_info.js";

import { getSections, getSectorsWithSections } from "@/api/competence.js";
import { getApprovedProjectsWithTasks } from "@/api/n_project.js";
import ButtonGradientInside from "@/components/Landing/ButtonInsideGradient";

export default {
  components: {
    Navbar,
    GradientButton,
    ButtonGradientBorder,
    RubleIcon,
    CircleQuestionIcon,
    JobHireIcon,
    ButtonGradientInside,
  },
  name: "BetaWelcomePage",
  data() {
    return {
      step: 1,

      // Step1:
      course: null,

      region_list: [],
      region_selected: "",
      region_other: "",

      univesities_list: [],
      univesities_id: "",
      univesities_other: "",

      reg_source_list: [
        "Мероприятие",
        "Институт",
        "Преподаватели",
        "Реклама",
        "Друзья",
        "Соц.сети (tg, vk и т.д.)",
        "Другое",
      ],
      reg_source: [],
      reg_source_other: "",

      reg_goal_list: [
        "Диплом",
        "Практика",
        "Развитие своего проекта",
        "Повышение своих компетенций",
        "Другое",
      ],
      reg_goal: [],
      reg_goal_other: "",

      // Step2:
      finishModal: false,

      Difficulties: [],

      ProjectsList: [],
      ProjectSelected: null,
      ProjectSelectedName: null,
      ProjectsLoading: true,

      nProjectsList: [],
      nProjectSelected: null,
      nProjectSelectedName: null,

      TasksList: [],
      TaskSelected: null,
      TasksLoading: false,

      nTasksList: [],
      nTaskSelected: null,

      MountedSelectProj: null,
      MountedSelectNProj: null,
      DOMReady: false,

      SelfProjectInfo: {
        name: "",
        description: "",
        result: "",
        effect: "",
        id: null,
      },
      SelfProjectEdit: false,
      Tip: "",

      sectorsSelected: [],
      sectorsList: [],

      sectionsSelected: [],
      sectionsList: [],

      userHasAttempts: false,
    };
  },
  watch: {
    sectorsSelected: {
      handler(val) {
        this.ProjectSelected = null;
        this.nProjectSelected = null;

        this.TaskSelected = null;
        this.nTaskSelected = null;
        this.TasksList = null;
        this.nTasksList = null;

        if (val == null || val.length == 0) {
          this.sectionsSelected = [];
        } else {
          this.sectionsSelected = this.sectionsSelected.filter((item) =>
            this.sectionsNameFromFilters.includes(item)
          );
        }
      },
    },
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    regionSelected() {
      return (
        (this.region_selected === "") |
        (this.region_selected === "Другое" && this.region_other === "")
      );
    },

    universitySelected() {
      const other_uni_id = this.univesities_list.find(
        (obj) => obj.id === this.univesities_id && obj.name === "Другое"
      );
      return (
        (this.univesities_id === "") |
        (this.univesities_id === other_uni_id && this.univesities_other === "")
      );
    },

    nextIsAvailable() {
      let RegionFilled = !this.regionSelected;

      let VUZFilled = !this.universitySelected;

      let SourceFilled =
        (this.reg_source !== "Другое" && this.reg_source.length != 0) ||
        (this.reg_source === "Другое" && this.reg_source_other.length >= 3);

      let GoalFilled =
        (this.reg_goal.length != 0 && this.reg_goal?.[0] !== "Другое") ||
        (this.reg_goal?.[0] === "Другое" && this.reg_goal_other.length >= 3);

      return (
        RegionFilled &&
        VUZFilled &&
        this.course != "" &&
        SourceFilled &&
        GoalFilled
      );
    },
  },
  async created() {
    await getRegionAndUniverList().then((response) => {
      this.region_list = response.data;
    });

    this.getUniverAndRegion();

    this.getUserRegData(this.user.id);
  },
  updated() {
    if (!this.DOMReady) {
      this.DOMReady = true;
    }
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),

    async updateUser() {
      const response = await refresh();
      const userResponse = response.user;
      this.getUser(userResponse.id);
    },
    getUser(id) {
      if (id != undefined) {
        findById(id).then((response) => {
          this.SET_USER(response.data);
        });
      }
    },

    checkCourseText(text) {
      text = text.replace(/[^\d]/g, "");

      if (text == "") {
        this.course = "";
        this.$refs.course.value = "";
      }

      if (text.length > 1) {
        text = text.substr(text.length - 1, 1);
      }

      if (
        String(text) === "1" ||
        String(text) === "2" ||
        String(text) === "3" ||
        String(text) === "4" ||
        String(text) === "5"
      ) {
        this.course = text;
        this.$refs.course.value = text;
      } else {
        this.course = "";
        this.$refs.course.value = "";
      }
    },
    getValidCourseValue(val) {
      if (isNaN(parseInt(val, 10)) == true) {
        return 0;
      } else {
        return parseInt(val, 10);
      }
    },

    async getUserRegData(__userID) {
      let res = null;

      try {
        res = await getUserRegistrationFields(__userID);
      } catch (error) {
        return;
      }

      if (res != null) {
        this.course = res.data.lvl == null ? "" : res.data.lvl;
        if (this.$refs?.course != null) {
          this.$refs.course.value = res.data.lvl == null ? "" : res.data.lvl;
        }

        if (res.data.goals.length > 0) {
          this.reg_goal = [];
          for (let obj of res.data.goals) {
            this.reg_goal.push(obj.type_value);
            if (obj.type_value == "Другое") {
              this.reg_goal_other = obj.other_value;
            }
          }
        }

        if (res.data.sources.length > 0) {
          this.reg_source = [];
          if (typeof res.data.sources === "string") {
            this.reg_source.push(res.data.sources);
          } else {
            for (let obj of res.data.sources) {
              this.reg_source.push(obj.type_value);
              if (obj.type_value == "Другое") {
                this.reg_source_other = obj.other_value;
              }
            }
          }
        }
      }
    },

    async getUniverAndRegion() {
      await getRegionAndUniver(this.user.id).then((response) => {
        let MapUniver = response?.data?.MapRegion;

        if (MapUniver == null || MapUniver?.UsersChoosedUniver == null) {
          return 0;
        }

        this.region_selected = MapUniver.UsersChoosedUniver.region.name;
        this.region_other =
          this.region_selected == "Другое" ? MapUniver.region_other : "";

        this.setUniveristiesList(MapUniver.UsersChoosedUniver.region.name);

        this.univesities_id = MapUniver.UsersChoosedUniver.id;
        this.univesities_other =
          MapUniver.UsersChoosedUniver.name == "Другое"
            ? MapUniver.univer_other
            : "";
      });
    },
    setUniveristiesList(selectedRegion) {
      this.univesities_list = [];
      this.univesities_id = "";

      let result = this.region_list.find((obj) => obj.name == selectedRegion);
      this.univesities_list = [...result.univers];
    },

    async sendUserRegData() {
      await addUserGoal({
        userID: this.user.id,
        goal: this.reg_goal,
        goal_other: this.reg_goal_other,
      });

      let _source =
        typeof this.reg_source === "string"
          ? [this.reg_source]
          : this.reg_source;

      console.log(_source);

      await addUserSource({
        userID: this.user.id,
        source: _source,
        source_other: this.reg_source_other,
      });

      if (this.registration_success == false) {
        this.registration_success = true;

        // await sendInfoDiscord({
        //   u_id: this.user.id,
        //   u_f: this.user.surname,
        //   u_i: this.user.name,
        //   u_o: this.user.patronymic,
        //   u_email: this.user.email,
        //   u_phone: this.user.tel,
        //   u_tg: this.user.tg_nick,
        //   u_city: this.city,
        //   u_univer: this.institute,
        //   u_direction: this.direction,
        //   u_course: this.course,
        //   u_goal: this.reg_goal,
        //   u_goal_other: this.reg_goal_other,
        //   u_source: this.reg_source,
        //   u_source_other: this.reg_source_other,
        // });
      }
    },

    async nextBtn(stepID) {
      if (this.regionSelected === 1) {
        this.Tip = "Укажите регион и учебное заведение";
      } else if (this.regionSelected === 0 && this.universitySelected === 1) {
        this.Tip = "Укажите учебное заведение";
      } else {
        this.Tip = "";
        if (this.region_selected != "Другое") {
          this.region_other = "";
        }

        await update(this.user.id, {
          university_model_id: this.univesities_id,
          university_model_other: this.univesities_other,
          region_model_other: this.region_other,
          lvl: this.getValidCourseValue(this.course),
        });

        await this.sendUserRegData();

        this.$router.push({ name: "ContentProjectsList" });
      }
    },
  },
};
</script>

<style scoped>
@import "./../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.Tip {
  color: #fff;
  font-size: 0.9rem;
  margin-left: 6px;
}
.btns {
  display: flex;
}
.btns div {
  margin-right: 0.5rem !important;
}
.LabelSelfProject {
  color: #e8e7ec;
  font-feature-settings: "calt" off;
  font-family: Nunito Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.0175rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  margin-bottom: 0.25rem;
}
.InputSelfProject {
  border-radius: 0.25rem;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  );
  backdrop-filter: blur(12.253599166870117px);

  display: flex;
  width: 100%;
  padding: 0.8125rem 0.75rem 0.75rem 0.75rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  color: #fff;
}
.InputSelfProject::placeholder {
  color: #413e74;
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 1.45313rem */
}

.FillProfileData {
  position: relative;

  width: fit-content;
  min-width: 428px;

  height: fit-content;
  max-height: 100%;

  padding: 30px;
  margin: 0% auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  border-radius: var(--o-s-global-border-radius);
  background: rgba(31, 30, 72, 0.6);

  flex-shrink: 1;
  overflow: hidden;
}
.FillProfileData > .Head {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;

  /* h3 */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.FillProfileData > .FillButton {
  width: 100%;
}

.FillProfileData > .Inputs {
  position: relative;

  width: 100%;
  height: fit-content;
  max-height: 100%;

  padding: 0px 9px 6px 6px;

  padding-right: 9px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  flex-shrink: 1;
  overflow: auto;
}
.FillProfileData > .Inputs > .InputWrap {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}
.FillProfileData > .Inputs > .InputWrap > .Label {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
}

.FillProfileData > .Inputs > .InputWrap > select,
.FillProfileData > .Inputs > .InputWrap > input {
  position: relative;

  width: 100%;
  height: fit-content;

  padding: 12px;

  border-radius: 4px;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgb(41, 37, 88) 4.06%,
    rgb(38, 35, 83) 42.35%,
    rgb(40, 38, 89) 99.53%
  );
  background-color: rgb(37, 31, 76);
  backdrop-filter: blur(12px);

  color: #fff;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}

.FillProfileData > .Inputs > .InputWrap > select:focus,
.FillProfileData > .Inputs > .InputWrap > input:focus-visible {
  outline: 2px solid rgb(130, 111, 238);
  border-radius: 3px;
}

option {
  color: #fff;

  font: -moz-pull-down-menu;

  -moz-font-family: "Montserrat";
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */

  background-color: transparent;
}

select option:hover {
  background-color: #36317a;
}
</style>

<style scoped>
.BetaWelcomePage {
  position: relative;

  display: flex;

  width: 100%;
  height: 100vh;
  min-height: 100svh;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  ) !important;
  background-position: 50% 50% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: auto 120% !important;
}
.BetaWelcomePage > .BWPContainer {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 100px;

  padding: 50px 120px 32px 120px;

  width: 100%;
  height: 100vh;
}

.Navbar {
  position: relative;
  display: block;

  width: 100%;
  height: 33px;

  background-color: rgba(0, 0, 0, 0.25);

  flex-shrink: 0;
}
.StepTip {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;

  width: fit-content;
  height: fit-content;

  padding: 20px 30px;
  margin: 0% auto;

  border-radius: 20px;
  background: linear-gradient(91deg, #1e768e 2.08%, #9652d4 98.48%);
  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);

  flex-shrink: 0;
}
.StepTip > * {
  margin-top: auto;
  margin-bottom: auto;
}
.StepTip > .StepTipImage {
  position: relative;
  display: block;

  width: 48px;
  height: 48px;

  background: url("./../assets/img/Landing/LogoWhiteBG48x48.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.StepTip > .StepTipText {
  position: relative;
  display: block;

  width: auto;
  max-width: 588px;
  height: fit-content;

  color: #fff;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
}
.StepTip > .StepTipText > .BoldTipText {
  font-weight: 700;
}

.ProjectsAndTasks {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  margin: 0% auto;

  width: 100%;
  max-width: 1680px;

  height: 100%;

  overflow: hidden;

  flex-shrink: 1;
}
.ProjectsAndTasks > .Projects {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  padding: 30px 20px 20px 20px;

  width: 405px;
  height: 100%;

  border-radius: var(--o-s-global-border-radius);
  background: rgba(31, 30, 72, 0.6);

  flex-shrink: 1;

  overflow: hidden;
}
.ProjectsAndTasks > .ProjectTasks {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  padding: 30px 20px 20px 20px;

  width: 405px;
  height: 100%;

  border-radius: var(--o-s-global-border-radius);
  background: rgba(31, 30, 72, 0.6);

  flex-shrink: 1;

  overflow: hidden;
}
.ProjectsAndTasks > .TaskInfo {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 25px;

  padding: 30px;

  width: 830px;
  height: 100%;

  border-radius: var(--o-s-global-border-radius);
  background: rgba(31, 30, 72, 0.6);

  flex-shrink: 1;

  overflow-y: auto;
}

.ProjectsAndTasks > .Projects > .TemplateHeader,
.ProjectsAndTasks > .ProjectTasks > .TemplateHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  color: #fff;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProjectsAndTasks > .Projects > .TemplateHeader > *,
.ProjectsAndTasks > .ProjectTasks > .TemplateHeader > * {
  margin: auto 0%;
}

.ProjectsAndTasks > .Projects > .TemplateDesc,
.ProjectsAndTasks > .ProjectTasks > .TemplateDesc {
  position: relative;

  margin-bottom: 22px;

  color: #fff;

  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ProjectsAndTasks > .Projects > .TemplateContainer,
.ProjectsAndTasks > .ProjectTasks > .TemplateContainer {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  width: 100%;
  height: auto;

  padding-right: 9px;

  overflow-y: auto;
}

.ProjectsAndTasks > .Projects .ProjectCard.SelectedCard {
  background: linear-gradient(98deg, #1f1e48 0%, #494888 100%);
}
.ProjectsAndTasks > .Projects .ProjectCard {
  position: relative;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  width: 100%;
  min-height: 142px;

  padding: 20px 30px;

  border-radius: var(--o-s-global-border-radius);
  background: #272655;

  height: auto;
  flex-shrink: 0;
}
.ProjectsAndTasks > .Projects .ProjectCard > .PCText {
  position: relative;
  display: block;

  color: #fff;

  width: 100%;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;

  hyphens: auto;
}
.ProjectsAndTasks > .Projects .ProjectCard > .TasksCounterAndUpdateDate {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.ProjectsAndTasks > .Projects .ProjectCard > .TasksCounterAndUpdateDate > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ProjectsAndTasks > .Projects .ProjectCard > .TasksCounterAndUpdateDate > span {
  color: #fff;

  /* tag */
  font-family: "Montserrat";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ProjectsAndTasks > .ProjectTasks .TaskCard.SelectedCard {
  background: linear-gradient(98deg, #1f1e48 0%, #494888 100%);
}
.ProjectsAndTasks > .ProjectTasks .TaskCard {
  position: relative;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  width: 100%;
  min-height: 180px;

  padding: 20px 30px;

  border-radius: var(--o-s-global-border-radius);
  background: #272655;

  height: auto;
  flex-basis: auto;
  flex-shrink: 0;
}
.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCFooter > *,
.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCFooter > .TCFLeft > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCFooter > .TCFLeft {
  position: relative;

  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}
.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCFooter > .TCFLeft > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;

  /* tag */
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCFooter {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCHeader {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.TCHeader > span {
  position: relative;
  display: block;

  width: auto;
  height: fit-content;

  color: #fff;

  /* text medium */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}
.TCHeader > .Upper {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  overflow: hidden;
  flex-wrap: wrap;
}
.TCHeader > .Upper > *:not(.RubbleIconStyle) {
  position: relative;

  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-top: auto;
  margin-bottom: auto;
}
.TCHeader > .Upper > .Directions {
  gap: 5px;
}
.TCHeader > .Upper > .Directions > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #1d1a42;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 4px 8px;

  border-radius: 4px;
  background: #1dd185;
}

.DotBlock {
  position: relative;
  display: block;

  width: 3px;
  height: 3px;

  border-radius: 7px;
  background: #fff;
}
.IconPeoples {
  position: relative;
  display: block;

  width: 16px;
  height: 12px;

  background: url("./../assets/img/Landing/Peoples16x12.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.IconHeart {
  position: relative;
  display: block;

  width: 20px;
  height: 18px;

  background: url("./../assets/img/Landing/HeartPurple.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  filter: drop-shadow(0px 0px 4px #ffffff60);
}
.SmallHeartIcon {
  position: relative;
  display: block;

  width: 15px;
  height: 14px;

  margin-left: 3px;

  background: url("./../assets/img/Landing/HeartPurple15x14.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.IconHeartFilled {
  position: relative;
  display: block;

  width: 20px;
  height: 18px;

  background: url("./../assets/img/Landing/HeartWhite.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  filter: drop-shadow(0px 0px 4px #fff);
}

.DifficultEmpty {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.DifficultFilled {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.ProjectsAndTasks > .TaskInfo > .HeadAndDesc {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}
.ProjectsAndTasks > .TaskInfo > .HeadAndDesc > .HDHead {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  padding-right: 120px;

  color: #fff;

  /* h3 */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProjectsAndTasks > .TaskInfo > .HeadAndDesc > .HDDesc {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  padding-right: 120px;

  color: #fff;

  /* text */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}

.ProjectsAndTasks > .TaskInfo > .HeadAndDesc > .HDTitle {
  color: #fff;

  /* h3 */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ProjectsAndTasks > .TaskInfo > .TopRightButton {
  position: absolute;
  display: block;

  cursor: pointer;

  width: fit-content;
  height: fit-content;

  top: 30px;
  right: 30px;

  z-index: 10;
}

.ProjectsAndTasks > .TaskInfo > .Competitions {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}
.ProjectsAndTasks > .TaskInfo > .Competitions > .CHead {
  color: #fff;

  /* h3 */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ProjectsAndTasks > .TaskInfo > .ChooseTask {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 25px;

  margin-top: auto;

  padding: 20px 30px;

  border-radius: var(--o-s-global-border-radius);
  background: #292558;
}

.ProjectsAndTasks > .TaskInfo > .ChooseTask > .LeftBlock {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.ProjectsAndTasks > .TaskInfo > .ChooseTask > .LeftBlock > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fafafa;

  /* text medium */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}
.ProjectsAndTasks > .TaskInfo > .ChooseTask > .LeftBlock > .Text {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.ProjectsAndTasks > .TaskInfo > .ChooseTask > .LeftBlock > .Text span {
  color: #c8c3de;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ChooseTask > .LeftBlock > .Text > .Upper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ChooseTask > .LeftBlock > .Text > .Upper > span {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.ChooseTask > .LeftBlock > .Text > .Upper > span > * {
  margin-top: auto;
  margin-bottom: auto;
}

.PinTask {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 10px 20px;
  margin: auto 0%;

  width: fit-content;
  height: fit-content;

  color: #fff;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;

  border-radius: 4px;
  background: #413e74;

  flex-shrink: 0;
}
</style>

<style scoped>
.AdditionalIconsJobs.Mobile {
  display: none;
}

.AdditionalIconsJobs {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;

  margin-left: auto;

  width: fit-content;
  height: fit-content;
}

.AdditionalIcons {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;

  top: 20px;
  right: 20px;

  width: fit-content;
  height: fit-content;
}
.RubbleIconStyle {
  margin-left: auto;
}

.FinishModalWrapper {
  position: fixed;

  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);
  z-index: 200;
}
.FinishModal {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  width: fit-content;
  height: fit-content;

  max-width: 583px;
  max-height: 90%;

  overflow-y: auto;

  padding: 48px;
  margin: auto;

  border-radius: 20px;
  background: linear-gradient(
      114deg,
      rgba(0, 234, 255, 0.2) 0%,
      rgba(29, 26, 66, 0.2) 48.44%,
      rgba(68, 0, 255, 0.2) 100%
    ),
    #1d1a42;

  z-index: 10;

  scrollbar-width: thin;
  scrollbar-color: #413e7400 transparent;
}
.FinishModal::-webkit-scrollbar {
  width: 0px;
}
.FinishModal::-webkit-scrollbar-track {
  background: transparent;
}
.FinishModal::-webkit-scrollbar-thumb {
  background-color: #413e7400;
  border-radius: 0px;
  border: 0px solid transparent;
}

.FinishModal > .Buttons {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  margin-top: 10px;
}
.FinishModal > .Buttons > * {
  margin-top: auto;
  margin-bottom: auto;
}
.FinishModal > .Buttons > .CloseLater {
  position: relative;
  display: block;

  color: #fff;
  text-align: center;

  cursor: pointer;

  /* text */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}

.FinishModal > .Text {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.FinishModal > .Text > .Header {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.FinishModal > .Text > .Header > * {
  margin-top: auto;
  margin-bottom: auto;
}
.FinishModal > .Text > .Header > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.FinishModal > .Text > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
}
</style>

<style scoped>
.CareerGuidance {
  position: relative;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 10px;

  width: 100%;
  min-height: 180px;

  padding: 20px 30px;

  border-radius: var(--o-s-global-border-radius);
  border: 2px solid #27235c;
  background: transparent;

  height: auto;

  flex-basis: auto;
  flex-shrink: 0;
}
.CGTitle {
  position: relative;
  display: block;
  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffff;
}
.CGDesc {
  position: relative;
  display: block;
  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #d3caffb2;
}
.CGButton {
  padding: 8px;
  width: fit-content;
  height: fit-content;
  margin: 0;
}
.CGButton:deep(p) {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}
.CGButton:deep(img) {
  margin-left: 6px;
  width: 10px;
  height: 10px;
}
</style>

<style scoped>
@media (max-width: 1500px) {
  .BetaWelcomePage {
    height: fit-content;
    overflow-y: auto !important;
  }
  .BWPContainer {
    height: auto !important;
    width: 80% !important;
    margin: auto;
  }

  .ProjectsAndTasks {
    flex-direction: column;
  }
  .ProjectsAndTasks > div {
    width: 100% !important;
    height: 500px !important;
  }
  .ProjectsAndTasks > div > .TemplateContainer {
    height: 500px !important;
  }
  .ChooseTask {
    flex-wrap: wrap;
  }
  .ChooseTask > .LeftBlock {
    hyphens: auto;
  }
}
@media (max-width: 1200px) {
  .StepTipImage {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .BWPContainer {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}
@media (max-width: 640px) {
  .BWPContainer {
    width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .SmallHeartIcon {
    display: none;
  }
  .FinishModal > .Buttons {
    flex-wrap: wrap;
  }
  .FinishModal {
    margin: 20px;
  }
}

@media (max-width: 480px) {
  .HideOnMobile,
  .AdditionalIconsJobs:not(.Mobile) {
    display: none !important;
  }

  .AdditionalIconsJobs.Mobile {
    display: flex;
    margin-left: 0;
  }

  .BetaWelcomePage > .BWPContainer {
    padding: 24px 60px;
    height: 100svh;
  }

  .FillProfileData {
    width: 100%;
    min-width: 0px;
  }

  .ProjectsAndTasks > .Projects .ProjectCard > .TasksCounterAndUpdateDate {
    flex-direction: column;
    gap: 10px;
  }

  .DotBlock {
    display: none;
  }

  .ProjectsAndTasks > .TaskInfo {
    padding: 0px;
  }

  .ProjectsAndTasks > .TaskInfo > .HeadAndDesc {
    padding: 0% 20px;
    padding-top: 20px;
  }

  .ProjectsAndTasks > .TaskInfo > .Competitions {
    padding: 0% 20px;
  }
  .ProjectsAndTasks > .TaskInfo > .ChooseTask {
    padding: 15px 20px;
  }
}

@media (max-width: 1500px) {
  .HideOnMobileWhenEmpty {
    display: none !important;
  }
  .BWPContainer {
    margin-top: 0% !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}
</style>
